import React, { useState, useCallback, useEffect } from 'react'
import Quill from 'quill'
import "quill/dist/quill.snow.css";
import "../styles.css"
import {io} from "socket.io-client"
import {useParams} from "react-router-dom"

const SAVE_INTERVAL_MS = 2000
const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["image", "blockquote", "code-block"],
    ["clean"],
  ]
function TextEditor() {

    const {id: documentId} = useParams()

    const [socket, setSocket] = useState()
    const [quill, setQuill] = useState()

    useEffect(()=>{
        const s = io("https://sauravdocscloneapi.vercel.app/")
        setSocket(s)
        return ()=> {
            s.disconnect()
        }
    },[])

    useEffect(() => {
        if (!socket || !quill) return;
      
        const handleLoadDocument = (document) => {
          quill.setContents(document);
          quill.enable();
        };
      
        socket.once("load-document", handleLoadDocument);
      
        socket.emit('get-document', documentId);
      
        // Cleanup function
        return () => {
          socket.off("load-document", handleLoadDocument);
        };
      }, [socket, quill, documentId]);
    
      useEffect(()=>{
        if (socket == null || quill == null) return

        const interval = setInterval(()=>{
            socket.emit('save-document', quill.getContents())
        }, SAVE_INTERVAL_MS)

        return ()=>{
            clearInterval(interval)
        }
      },[socket, quill])

    useEffect(()=>{
        if(socket == null || quill == null) return

        const handler = (delta)=>{
            quill.updateContents(delta)
        }
        socket.on('receive-changes', handler)

        return ()=> {
            socket.off('receive-changes', handler)
        }
    },[socket, quill])

    useEffect(()=>{
        if(socket == null || quill == null) return

        const handler = (delta, oldDelta, source)=>{
            if (source !== 'user') return
            socket.emit("send-changes", delta)
        }
        quill.on('text-change', handler)

        return ()=> {
            quill.off('text-change', handler)
        }
    },[socket, quill])
    
    const wrapperRef = useCallback((wrapper)=>{

        if(wrapper == null) return
        wrapper.innerHTML = ''

        const editor = document.createElement('div')
        wrapper.append(editor)

        const q = new Quill(editor, {theme: "snow", modules: {toolbar: TOOLBAR_OPTIONS}})
        
        q.disable()
        q.setText('Loading...')
        setQuill(q)
        
        
    },[])

  return (
    <div className='container' ref={wrapperRef}></div>
  )
}

export default TextEditor